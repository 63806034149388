import "components/order/style/OrderDetail.css";

const OrderContentsNoPoint = ({
  payIndex,
  basket,
  basketDeleteHandler,
  priceChangeHandler,
  pointHandler,
}) => {
  const MenuListComponent = () => {
    const mList = basket.rows.map((menu, index) => {
      const MenuCateList = ({ mCateList }) => {
        return mCateList.map((cate) => {
          return (
            <>
              <div className="order_option">
                <p className="option_area">{cate.moc_name}</p>
              </div>
              {cate.optionArray.map((opt) => {
                return (
                  
                  <li style={{ display: 'none' }}>
                    <span className="opction-class"></span>
                    <div className="opciton-price-box">
                      <span className="opction-name">
                        <p>└</p>
                        {opt.mo_name}
                      </span>
                      <span>
                        <p>
                          {opt.mo_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          <span className="font-weght-light">원</span>
                        </p>
                        <p>
                          {opt.mo_count}
                          <span className="font-weght-light">개</span>
                        </p>
                      </span>
                    </div>
                  </li>
                );
              })}
            </>
          );
        });
      };

      return (
        <>
         {menu.m_type === "A" && (
          <div className="order-menu-name-box" data-tprice={menu.totalPrice}>
            <span className="menu-name">{menu.m_name}</span>
            {basket.s_idx != "12527" ?( 
            <span className="menu-price">
            
              <p>
                {menu.optionCateArray.length > 0
                  ? menu.totalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : menu.m_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {""}
                <span className="font-weght-light2">원</span>
              </p>
             
                             
              <p>
                {menu.m_count}
                <span className="font-weght-light2">개</span>
              </p>
              <button
                className="order-cancel-button"
                onClick={() => {
                  basketDeleteHandler(menu.bm_idx);
                  pointHandler.pointReset();
                }}
              ></button>
            </span>
            ) : (
              <span className="menu-price">
                <p>
                 <input type="text" style ={{textAlign:"right"}} placeholder={menu.totalPrice}
                 onKeyDown={(event) => {
                    if (!((event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 8 || event.keyCode === 46 )) {
                      event.preventDefault();
                    }
                  }} 
                  onChange={(event) => {
                   
                   }}
                    onBlur={(event) => {
                     event.target.value = event.target.value.replace(/[^0-9]/g, "");
                     priceChangeHandler(menu.bm_idx, event.target.value);
                     pointHandler.pointReset();
                    }}/>
                   <span className="font-weght-light2">원</span>
                </p>
                <p>
                  {menu.m_count}
                  <span className="font-weght-light2">개</span>
                </p>
                <button
                  className="order-cancel-button"
                  onClick={() => {
                    basketDeleteHandler(menu.bm_idx);
                    pointHandler.pointReset();
                  }}
                ></button>
              </span>
            )}
          </div>
         )}
        </>
      );
    });

    return <ul className="order-content-list">{mList}</ul>;
  };
  return (
    <div className="order-content">
      {/* <div className="underline"></div> */}
      <div className="wrap_frame">
        <div className="wrap_frame-wrap">
          <div className="order-content-title">
            <span className="order-title-light">포인트 사용 불가 상품</span>
            <button className="drop-down"></button>
          </div>
          {basket && <MenuListComponent />}
        </div>
      </div>
    </div>
  );
};

export default OrderContentsNoPoint;
