import "components/order/style/OrderDetail.css";

const OrderPayType = ({ isNotMeet }) => {
  return (
    <div className="payment_frame">
      <div className="wrap_frame">
        <div className="payment">
          <span className="payment-title">결제 수단</span>
          <ul className="payment-list">
            <input
              type="radio"
              id="payment-credit"
              name="payment-type"
              value="11"
             // defaultChecked
             disabled= "true"

            onClick={() =>{
              const countInfo = document.getElementById("countinfo");
              countInfo.style.display = "none";
               alert('준비중입니다');
              }}
            />
            <label htmlFor="payment-credit"></label>
            <input
              type="radio"
              id="payment-phone"
              name="payment-type"
              value="31"
              defaultChecked
           //   disabled= "true"
          
           onClick={() => {
            const countInfo = document.getElementById("countinfo");
            countInfo.style.display = "block";
            }}
            />
            <label htmlFor="payment-phone"></label>
            {/* 비활성화 */}
            <input 
              type="radio"
              id="payment-meet"
              name="payment-type"
              value="2"
              disabled={isNotMeet}
            />
            <label htmlFor="payment-meet" style={{display:(!isNotMeet?"":"none")}}></label>
            <input
              type="radio"
              id="payment-cash"
              name="payment-type"
              value="1"
              disabled={isNotMeet}
            />
            <label htmlFor="payment-cash" style={{display:(!isNotMeet?"":"none")}}></label>
            {/* 비활성화  끝*/}
          </ul>
          <div id="countinfo">
          <p className="Precautions">
          { <span className="font-weight"> 입금계좌:우리은행 1005-503-785077 백옥생코리아(주)</span>
                }
          </p>
          <p className="Precautions">
          { <span className="font-weight"> 반드시 주문자 이름으로 입금부탁드립니다</span>
                 }
          </p>
          <p className="Precautions">
          { <span className="font-weight"> 입금 확인이 된 이후 출고처리가 진행됩니다</span>
                 }
          </p>
          <p className="Precautions">
          { <span className="font-weight"> 포인트 사용 후 총 결제금액을 입금해 주세요</span>
                 }
          </p>
          </div>
          <p className="Precautions">
            
            {isNotMeet && (
              <>
               
                {/* <span className="font-weight"> 결제 수단을 다시 선택</span>
                해주세요! */}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderPayType;
