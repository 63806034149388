import { useNavigate } from "react-router-dom";
import { setOrder,
  changeName,
  changeTel } from "service/OrderService";

import "components/order/style/OrderDetail.css";

const FinalConfirm = ({
  sIdx,
  basketList,
  totalAmt,
  usePoint,
  isPorc,
  setIsProc,
  setIsCreate,
  setOdIdxList,
  totalDelFee,
  delFeeList,
  addrCheck,
  userInfo,
}) => {
  const navigate = useNavigate();
  const isPhoneNumber = (value) => {
    const phoneNumberRegex = /^\d{3}\d{3,4}\d{4}$/;
    return phoneNumberRegex.test(value);
  };
  const orderCreateEvent = () => {
    
    console.log(basketList);
    if (isPorc) {
      alert("이미 결제가 진행중입니다.");
      console.log("이미 결제가 진행중입니다.");
      return;
    }
    // 주소확인
    const addCheckList = document.querySelectorAll(
      'input[id^="address-checkbox"]'
    );
   
    const directCheckList = document.querySelectorAll(
      'input[id^="direct-checkbox"]'
    );
    const telCheckList = document.querySelectorAll(
      'input[id^="tel-checkbox"]'
    );
    const mb_addrCheckList = document.querySelectorAll(
      'p[id^="mb_addr"]'
    );
    for (let i = 0; i < addCheckList.length; i++) {
      const element = addCheckList[i];
      const element2 = directCheckList[i];
      
      if (!element.checked && !element2.checked) {
        element
          .closest(".delivery-info")
          .scrollIntoView({ behavior: "smooth" });
        alert("주소,직접수령중 하나 체크박스를 클릭, 확인해주세요.");
        return;
      }
    }

    for (let i = 0; i < mb_addrCheckList.length; i++) {
      const element = mb_addrCheckList[i].innerText;      
      if(element.trim().length <=11){
        alert("주소를 수정해 해주세요.");
        return;
      } 
    }
    if(document.getElementById("tel-phone") != null && document.getElementById("mb-name") != null){
    let rqArg = {};
    rqArg.mb_id = userInfo.mb_id;
       rqArg.mb_phone =  document.getElementById("tel-phone").value;
       if((rqArg.mb_phone.trim().length <=0 || !isPhoneNumber(rqArg.mb_phone)) && ( (!isPhoneNumber(document.getElementById("tel-phone").placeholder) || document.getElementById("tel-phone").placeholder.replace(/\s+/g, '') ==''))){
        //console.log("전화번호를 다시 입력해주세요."+rqArg.mb_phone.trim().length+" "+isPhoneNumber(rqArg.mb_phone)+" "+document.getElementById("tel-phone").placeholder.replace(/\s+/g, '')+" "+document.getElementById("tel-phone").placeholder+" "+rqArg.mb_phone);
        alert("전화번호를 다시 입력해주세요.");
        return;
       }
      
        if((rqArg.mb_phone != document.getElementById("tel-phone").placeholder) && rqArg.mb_phone.trim().length >0 && isPhoneNumber(rqArg.mb_phone)){
      changeTel(rqArg).then((res) => {
        if (res.success) {
          document.getElementById("tel-phone").value = rqArg.mb_phone; 
          document.getElementById("tel-phone").placeholder = rqArg.mb_phone;
          //alert("전화번호가 변경되었습니다.");
          console.log("성공");
        }
      }); 
    }
      let rqArgN = {};
      rqArgN.mb_id = userInfo.mb_id;
      rqArgN.mb_name =  document.getElementById("mb-name").value;     
      if(rqArgN.mb_name.replace(/\s+/g, '').length <=0 && (document.getElementById("mb-name").placeholder=='이름입력' || document.getElementById("mb-name").placeholder.replace(/\s+/g, '') =='')){
        //console.log("이름을 다시 입력해주세요."+rqArgN.mb_name.replace(/\s+/g, '').length+" "+document.getElementById("mb-name").placeholder.replace(/\s+/g, '')+" "+document.getElementById("mb-name").placeholder);
        alert("이름을 다시 입력해주세요.");
        return;
       } 
       if(rqArgN.mb_name.length != document.getElementById("mb-name").placeholder && rqArgN.mb_name.replace(/\s+/g, '').length >0){
        changeName(rqArgN).then((res) => {
          if (res.success) {
            document.getElementById("mb-name").value = rqArgN.mb_name;
            document.getElementById("mb-name").placeholder = rqArgN.mb_name;
           
            console.log("성공");
          }
        }); 
      }
    }  

    for (let i = 0; i < telCheckList.length; i++) {
      const element = addCheckList[i];
      const element3 = telCheckList[i];
     
      if (!element3.checked) {
        element
          .closest(".delivery-info")
          .scrollIntoView({ behavior: "smooth" });
          alert("연락처 정보를 체크박스를 클릭, 확인해주세요.");
        return;
      }
    }
    for (let i = 0; i < basketList.length; i++) {
      const basket = basketList[i];
      //기프티콘 임시 차단
      if (basket.c2_idx == "21") {
        window.alert("준비중입니다.");
        return;
      }
      if (!addrCheck(basket)) {
        return;
      }
    
      const odOption = basket.od_option * 1;
      const isRest = basket.is_rest * 1;
      const isRun = basket.is_run * 1;
      // 시간확인
      if (odOption < 4) {
        // TODO: kk_shop 은 있는데 d_rest가 없으면 여기 코드추가해야함
       /* if (isRest === 1 || isRun === 0) {
          alert(`${basket.s_name} 은 영업시간이 아닙니다.`);
          return;
        }*/
      }
    }
    
   /* let pay_type = document.querySelector(
      'input[name="payment-type"]:checked'
    ).value;*/
    let pay_type = "3";
    if (totalAmt + totalDelFee - usePoint < 1) {
      pay_type = "3";
    }
    if((totalAmt + totalDelFee)!=usePoint && !document.getElementById("payment-phone").checked){
      alert("계좌이체를 선택해 주세요.");
      return;
 
    }
    let multipay = false;
    let tempList = [];
    
    basketList.forEach((basket, index) => {
      const article = document.querySelector(".order-wrap" + basket.s_idx);
      const directCheck = article.querySelectorAll( 'input[id^="direct-checkbox"]')[0];
      const safeNum =
        article.querySelector('input[id^="safe-number"')?.checked || false;
      const ceoMsg = article.querySelector(".ceo-msg")?.value || "";
      var riderMsg = article.querySelector(".rider-msg")?.value || "";
      if(directCheck){
      if(directCheck.checked){
        riderMsg="직접 수령입니다.";
      }
      if(directCheck.checked && ceoMsg==""){
        alert('아산, 서울사무실 중 원하는 수령지를 요청사항에 남겨 주세요.');
        return
           

      }
    }
      const needFork =  false;
      const shopUsePoint =
        article
          .querySelector('input[id^="point-use-input"')
          .value.replace(",", "")
          .replace(",", "") * 1 || 0;
      const deliveryFee = delFeeList[index];

      let _pay_type = "3";
      if (pay_type != "3") {
        // 배송비, 상품금액, 사용포인트 계산 필요
        if (basket.totalPrice + deliveryFee - shopUsePoint > 0) {
          _pay_type = pay_type;
          multipay = true;
        }
      }
    
      tempList.push({
        s_idx: basket.s_idx,
        od_option: basket.od_option,
        sp_pay_type: _pay_type,
        od_use_point: shopUsePoint,
        od_msg_rider: riderMsg == "" ? "빠른 배송 부탁드립니다." : riderMsg,
        od_msg_ceo: ceoMsg == "" ? "수령지를 말씀해주세요." : ceoMsg,
        od_is_use_v_hp: safeNum,
        sp_product_amt: basket.totalPrice,
        user_order: basket.rows,
        od_delivery_tip: deliveryFee,
        needFork: needFork,
      });
    });

    if (tempList.length > 1 && pay_type > 3 && multipay) {
      multipay = true;
    } else {
      multipay = false;
    }
  
    setIsProc(true);
    setOrder({
      orderList: tempList,
      multipay: multipay,
    })
      .then((res) => {
        alert("성공적으로 주문이 완료되었습니다."+res.od_idx_list);
        navigate("/order");
        //setOdIdxList(res.od_idx_list);
        
       
      })
      .catch((err) => {
        alert(" 주문이 실패하였습니다.");
        console.error("setOrder catct", err);
        setIsProc(false);
        if (err && err.msg) {
          alert(err.msg);
          setIsProc(false);
        }
      });
  };

  const GiftGuide = () => {
    const giftGuide = basketList.map((shop, index) => {
      if (shop.s_idx == "10363") {
        return (
          <>
            <p>미사용 기프티콘의 환불은 구매 후 5일 이내에 가능합니다.</p>
            <p>기프티콘의 유효기간은 30일이며 유효기간 종료 후</p>
            <p>환불/기간연장은 불가능합니다.</p>
          </>
        );
      }
    });
    return giftGuide;
  };

  return (
    <div className="final-confirmation">
      <div className="underline"></div>
      <div className="wrap_frame">
        <div className="payment-result">
          <div className="chek_box_area">
            {/* <input
              type="checkbox"
              id="address-checkbox5"
              name="address-checkbox"
            />
            <label htmlFor="address-checkbox5" />
            <span>전체결제하기</span> */}
          </div>

          <p className="pay_number">결제금액</p>
          <ul className="payment-result-list">
            <li>
              <span>주문금액</span>
              <span>
              
                {totalAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                <span className="font-weght-light2">원</span>
              </span>
            </li>
            {sIdx != "10363" ? ( // 이쁨상점일때 배송비 비활성화
              <li>
                <span >배송비</span>
                <span id="delFee">
                  {totalDelFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  <span className="font-weght-light2">원</span>
                </span>
              </li>
            ) : (
              ""
            )}
            <li>
              <span>포인트</span>
              <span>
                {usePoint > 0 && "-"}
                {usePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                <span className="font-weght-light2">원</span>
              </span>
            </li>

            {/* 
            묶음주문이 된다면 처리해야됨
            영화관만 할인적용
            최대 4천 포인트사용
            2천원 할인 하드코딩임 
            */}
            {basketList[0]?.s_idx == 10228 && (
              <li>
                <span>상품할인</span>
                <span>
                  {/* {usePoint > 0 && "-"} */}
                  {/* {usePoint
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    {" "} */}
                  -
                  {(2000 * basketList[0]?.rows.length)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  <span className="font-weght-light2">&nbsp;원</span>
                </span>
              </li>
            )}
          </ul>
          <span className="total-payment-result">
            <p>총 결제금액</p>
            <p id="totalAmt">
              {/* 무조건 2000원 할인 CGV */}
              {sIdx == 10228
                ? (
                    totalAmt -
                    2000 * basketList[0].rows.length -
                    usePoint +
                    totalDelFee
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : (totalAmt - usePoint + totalDelFee)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              <span className="font-weght-light3">원</span>
            </p>
          </span>
        </div>
      </div>
      <div className="kiikey-guide">
        <p>이쁨은 통신판매 중개자이며 통신판매의 당사자가 아닙니다.</p>
        <p>따라서 이쁨은 상품거래 정보 및 거래에 책임을 지지 않습니다.</p>
        {basketList && <GiftGuide />}
      </div>
      <button className="payment-button" onClick={orderCreateEvent}>
        <span id="totalAmt2">
          {sIdx == 10228
            ? (
                totalAmt -
                2000 * basketList[0].rows.length -
                usePoint +
                totalDelFee
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : (totalAmt - usePoint + totalDelFee)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
        원 결제하기
      </button>
    </div>
  );
};

export default FinalConfirm;
